<template>
  <main class="privacy-policy">
    <div class="container">
      <h1 id="main-title">Privacy Policy</h1>
      
      <div class="policy-content">
        <!-- Introduction -->
        <p>Reward VPN, is committed to protecting your privacy. We strictly limit the collection scope of your data. Only data required to maintain our service is collected.</p>
        
        <p>This Privacy Policy describes how we collect, use, and share your data in connection with your use of our websites (including www.getrewardvpn.com), services and applications (collectively, "Reward VPN"). This Privacy Policy does not apply to data that you send and/or receive from third party websites through Reward VPN.</p>

        <!-- Section 1 -->
        <h2>1. COLLECTION OF DATA</h2>
        
        <h3>1.1 Data not to be collected</h3>
        <p>We do not collect the following data:</p>
        <p>(1) Addresses (including residential addresses and business addresses);</p>
        <p>(2) Telephone numbers; and</p>
        <p>(3) Payment details:</p>
        <p>a.We do not collect your payment details for Reward VPN. We do not collect or store credit card numbers, bank account numbers, or billing records.</p>
        <p>b. When you make payments to us, Reward VPN may redirect you to the website of a third party payment processor to complete the transaction. If you would like to understand what personal data the third party processor collects and stores, please kindly refer to the respective processor's terms and privacy policy.</p>

        <p>We do not collect sensitive data as we have engineered our systems to categorically erase the storage of sensitive data.</p>
        
        <p>We do not collect any data related to your online activities. We do not collect data logs of your activities, including:</p>
        <p>(1) Browsing history;</p>
        <p>(2) Traffic destination;</p>
        <p>(3) Data content; and</p>
        <p>(4) DNS queries.</p>

        <p>We do not collect data of your connection logs, including:</p>
        <p>(1) Your IP address;</p>
        <p>(2) Your outgoing VPN IP address;</p>
        <p>(3) Connection timestamp; and</p>
        <p>(4) Session duration.</p>

        <p>As we do not collect data logs of your activity or connection logs, we would not have information about:</p>
        <p>(1) The websites you visit;</p>
        <p>(2) The set of original IP addresses of your computer or the temporarily assigned network addresses.</p>

        <h3>1.2 Data to be collected</h3>
        <p>We limit the collection of your data. We only collect data that is required to maintain the services by Reward VPN.</p>

        <h4>1.2.1 Data provided to us</h4>
        <p>We collect the data that you provide to us through your interaction 
          with Reward VPN. When you register for an Account, we may ask for your email address. 
          The amount of data that you provide us depends on your privacy settings.</p>
          <p>We use your name and email to:</p>
        <p>(1) Communicate with you;</p>
        <p>(2) Send you updates and announcements; and</p>
        <p>(3) Send marketing information, such as our offers, surveys, invitations and other content.</p>

        <p>If you contact us directly, we may receive data such as your name, email address, phone number, content of the message and/or attachments, and any other personal data you may choose to provide to us in your communication to us.</p>
        
        <p>You may withdraw your consent to our use of your email address for the above purposes.</p>

        <h4>1.2.2 Data necessary for us to maintain the service quality of Reward VPN</h4>
        <p>We collect the following data related to your use of Reward VPN:</p>
        <p>(1) Data related to versions of Reward VPN: we collect data related to your version of Reward VPN. This allows us to efficiently identify and eliminate technical issues that you may face.</p>
        <p>(2) Data related to VPN connection: we collect abstracted data related to your use of Reward VPN. This would include success of VPN connections, VPN location you connected to, your country, and your internet service provider. You cannot be identified from this abstracted data.</p>
        <p>(3) Amount of data transferred: we collect the aggregate sum of data transferred to ensure that individual users do not use excessive data at the expense of other users' experience.</p>
        <p>(4) Anonymous data for network diagnostics, study and analysis: We collect anonymized data to operate Reward VPN, including diagnostic data such as VPN connection failures, speed test data, and crash reports.</p>

        <h2>2. USE OF DATA</h2>
        <p>We use, combine, and process your data for the following purposes:</p>

        <h3>2.1 Security</h3>
        <p>We authenticate your use of Reward VPN by assigning a unique identifier to you when you register for the service via your email address. This unique identifier, without reference to other data, identifies you through your device identification code. The purpose of the unique identifier is for us to determine the level of service you have signed up for before we establish your Reward VPN connection.</p>
        <p>We do not use any other forms of your data to verify your identity.</p>

        <h3>2.2 System diagnostics</h3>
        <p>We use the data related to your Reward VPN usage to perform internal operations necessary to maintain services of Reward VPN. This includes operational troubleshooting, conducting data analysis & activity trend analysis.</p>

        <h3>2.3 Customer support</h3>
        <p>We use your data to resolve customer support issues. For example, we may investigate and address concerns, monitor and improve our customer support, respond to your questions and feedback, and inform you of suggestive steps to resolve certain regular customer issues.</p>
        <p>We may communicate with you to provide updates relating to Reward VPN, and for marketing & promotional purposes.</p>

        <h3>2.4 Research and development</h3>
        <p>We use your data to understand and analyze your use of Reward VPN to develop new products, services, features and functionalities for you.</p>

        <h3>2.5 Service providers</h3>
        <p>We use data from service providers and third-party vendors to provide and market Reward VPN.</p>

        <h2>3. SHARING AND TRANSFER OF DATA</h2>
        <p>We share and transfer your data in various ways, including the following:</p>

        <h3>3.1 Aggregated anonymous data</h3>
        <p>We use and share your data with our partners in aggregated or anonymized form that cannot be used to identify individual users.</p>

        <h3>3.2 Referral</h3>
        <p>If you sign up for Reward VPN through a friend's referral, we may let your friend know that you sign up for Reward VPN via his/her referral.</p>

        <h3>3.3 Analytics</h3>
        <p>We share and transfer your anonymized statistics and analytical data for network diagnostics.</p>

        <h3>3.4 Business transfers</h3>
        <p>Your data may be disclosed and otherwise transferred to potential acquirer, successor, or assignee as part of any proposed merger, acquisition, debt financing, sale of assets or similar transaction or in the event of insolvency, bankruptcy, or receivership.</p>
        <p>In order to complete the payment process and send you a payment receipt, we will share your email address with third-party payment companies. The payment receipt is one of the necessary documents for you to apply for after-sales or refund, please keep it safe.</p>
        <h2>4. SECURITY MEASURES</h2>
        <p>We implement robust security measures to protect your data from unauthorized access, collection, use, disclosure, copying, modification, disposal or similar risks. We ensure the security of our computer networks and sound adoption of control measures. Access of our staff to your data is restricted to a strict need-to-know basis.</p>
        <p>We do not collect or store any traffic data or personal data in our data centers.</p>

        <h2>5. DATA RETENTION</h2>
        <p>We retain your data on the condition that we have a legitimate ongoing business need to do so, such as providing you with service you requested, or complying with applicable legal, tax, or accounting requirements.</p>
        <p>When there is no legitimate ongoing business need to process your data, we will delete or anonymize it. If it is impossible to delete or anonymize your data (for instance, because it has been stored in backup archives), then we will securely store it and isolate it from any further processing until deletion is feasible.</p>

        <h2>6. RIGHT TO BE FORGOTTEN</h2>
        <p>We will erase your data when:</p>
        <p>(1) Your data is no longer necessary for the purpose which we originally collected or processed it for;</p>
        <p>(2) You withdraw your consent to us holding your data, and we are relying on your consent as the lawful basis for holding your data;</p>
        <p>(3) You object to our processing of your data, and there is no overriding legitimate interest for us to continue such processing; or</p>
        <p>(4) We are processing your data for direct marketing purposes and you object to the processing thereof.</p>
        
        <h2>7. THIRD PARTY SERVICES</h2>
        <p>You may access other third party services while using Reward VPN, for example, by clicking on links of third party services from within Reward VPN. We shall not be held responsible for the privacy policies and/or practices of these third party services. You are encouraged to carefully review their privacy policies.</p>

      <h2>8. ADVERTISING</h2>
      <p>(1) Paid Reward VPN</p>
      <p>For paid Reward VPN, we do not work with third party advertising partners.</p>
      <p>(2) Free Reward VPN</p>
      <p>For free Reward VPN, we work with third party advertising partners to offset the cost of providing our services. We do not target advertisements based on your data. We do not share or use your data in connection with third party advertisers without your prior consent.</p>
      <p>Third party advertising partners may set and access their own cookies, pixel tags, and similar technologies on our services, and may otherwise collect or have access to data about you which they may collect over time and across different online services.</p>

      <h2>9. CHILDREN'S PRIVACY</h2>
      <p>We encourage parents and guardians to teach their children about privacy and how their information may be used on the Internet. Children should never disclose their name, address or phone number, or any personal information, without their parents' or guardian's prior permission.</p>
      <p>We consider a user to be a child if they are under the age of 13, unless more stringent regulation applies in their state of residence.</p>
      <p>If we consider that any of our services are directed at users under the minimum legal age required in their country of residence, we can block such users from using the services or from providing information and/or limit the collection of information from such users as described below in more details.</p>
      <p>No personal information should be submitted to Reward VPN by users under the minimum legal age required in their state of residence. We apply the following limitations to personal information collection for a user that does not meet the minimum legal age required in their state of residence.</p>
      <p>Such a user cannot:</p>
      <p>display their real name or any information in Reward VPN</p>
      <p>access the chat, leaderboard, forums, SNS (Facebook, Game Center, etc.)</p>
      <p>subscribe to newsletters</p>
      <p>use any other feature where users' real names might appear</p>
      <p>With respect to such users, we will prevent the collection and use of their precise geolocation data and display to them contextual advertising only (excluding any behavioral advertising).</p>
      <p>Children can be invited to take part in testing our websites & apps with their parents' or guardians' authorization and/or their parents' or guardians' physical presence being required depending on the state where testing is conducted.</p>
      <p>Parents must exercise their privacy rights for their children provided in this Privacy Policy.</p>

      <h2>10. ORDERS COMPELLING THE DISCLOSURE OF YOUR DATA</h2>
      <p>Our collection of your data is strictly limited. We collect your device identification code, and other data you may volunteer to us when you voluntarily contact us by way of email, phone, or interactions at our website or on Reward VPN's application page on Google Play or App Store. We do not collect IP addresses, browsing history, traffic data, or DNS queries that could be used to identify you. As such, the extent of any disclosure of your data is limited.</p>
      <p>We are committed to safeguarding your data. Nonetheless, we may be compelled to disclose your data where we are served with a valid court order that is enforceable in United States, an order from law enforcement agencies, or where we are legally obligated to disclose your data. We will require the recipient of your data to undertake the protection of your data, to a standard that is at least comparable to the protection required under United States's Personal Data Protection Act ("PDPA").</p>

      <h2>11. ACCESS</h2>
      <p>When you become a user of Reward VPN, you may request access to your data that is associated only with you by emailing support@getrewardvpn.com .</p>
      <p>To protect your privacy and security, we may take reasonable steps to verify your identity before acceding to your request. Upon our approval of your access request, we will provide you with your data that is in our possession or under our control. Where applicable, we will provide you with information about the ways in which your data has been or may have been used or disclosed by us within a year of your request. We may charge you a fee for fulfilling the access request.</p>

      <h2>12. CORRECTION</h2>
      <p>You may make a request to correct an error or omission about your data that is in our possession or control, by emailing support@getrewardvpn.com</p>
      <p>We will consider whether the correction should be made. If such correction is to be made, we shall correct your data as soon as practicable and send the corrected data to every other organization to which your data was disclosed by us within a year before the date the correction request was made, unless that other organization does not need the corrected data for any legal or business purpose.</p>
      <p>To protect your privacy and security, we may take reasonable steps to verify your identity before correcting your data. Your data may be archived or stored periodically by us according to backup processes conducted in ordinary course of business for disaster recovery purposes.</p>

      <h2>13. GDPR</h2>
      <p>If you are a resident of the EEA, we ask that you keep the information that you provide to us updated.</p>
      <p>You represent and warrant that all information you provide us is true and correct, and that the info relates to you and not to any other person.</p>
      <p>You can exercise your rights at any time and ask Reward VPN for access to, or rectification or erasure of, your information.</p>
      <p>Please note that other third parties (our data partners) may be controllers of your information. You can obtain more details and opt out of the sharing of your personal information to third parties for any purpose by contacting us by emailing support@getrewardvpn.com .</p>
      <p>If you have previously consented to any direct marketing communication from us or subscribed to our newsletters, you can unsubscribe by clicking on a link available in each communication and newsletter we send you.</p>
      <p>Please note that VPN functionality, services and features can be affected by your actions. Some of your information and processing thereof is mandatory for Reward VPN to provide the services.</p>
      <p>Your actions may force Reward VPN to cease providing services. When the conditions below apply, services will be reset:</p>
      <p>- If you ask for the modification of this mandatory information, we may be unable to continue providing Reward VPN services for you.</p>
      <p>- If you request limitations to processing that are mandatory for Reward VPN, or you object to such processing (in which case, we may not be able to provide you with Reward VPN or our other services).</p>
      <p>- If you withdraw your consent or ask for the deletion of your information.</p>
      <p>- If you withdraw your consent or ask for the deletion of your information, we will not use or process your information any more, but we will store it to meet regulatory or police requests. We also reserve the right to retain an archive of such information for a commercially reasonable time to ensure that its deletion does not affect the integrity of our data; and we further reserve the right to retain an anonymous version of such information.</p>
      <p>Please specify your request by emailing support@getrewardvpn.com .</p>

      <h2>14. CCPA</h2>
      <p>The California Consumer Privacy Act of 2018 (CCPA) permits California residents to request from a business, with whom the California resident has an established business relationship, certain information about the types of personal information the business has collected, shared with, and/or sold to third parties for a business or commercial purpose during the immediately preceding calendar year.</p>
      <p>As a Californian consumer, you also have the right, at any time, to opt out of any type of selling your personal information.</p>
      <p>Please note that we do not sell your personal information to provide Reward VPN or our services described in this Privacy Policy. Our partners and providers who work with us may have access to your personal information in order to provide you with the services within the limits and throughout the duration of our agreement, but they cannot reuse your personal information for any other purposes. Our contracts are strictly drafted to protect your personal information and your privacy rights.</p>

      <h2>15. BREACH NOTIFICATION</h2>
      <p>We will report serious breaches of your data security to relevant supervisory authority to ensure the effective protection of your data.</p>


      <h2>16. CHANGES TO THIS PRIVACY POLICY</h2>
      <p>To ensure compliance with applicable laws, this Privacy Policy may be modified from time to time without prior notice to you. Your continued use of Reward VPN constitutes your acceptance of our Privacy Policy. Changes to this Privacy Policy will be posted on our websites.</p>
      <p>If we materially change the ways in which we use or share your data previously collected through Reward VPN, we will notify you through Reward VPN, email, or other communication.</p>
        <h2>17. GOVERNING LAW</h2>
        <p>This Privacy Policy is governed by and construed in accordance with the laws of the United States.</p>
        <h2>18. DATA PROTECTION OFFICER</h2>
        <p>Eman Issa is our Data Protection Officer ("DPO"). The DPO may be contacted at dataprivacyofficer@getrewardvpn.com .</p>
        <p>If you have any questions or concerns about this Privacy Policy, please feel free to contact us at support@getrewardvpn.com.</p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped>
.privacy-policy {
  padding: 2rem 1rem;
  color: #e1e1e1; /* Lighter text for dark theme */
  line-height: 1.6;
  background-color: #121212; /* Dark background */
  min-height: 100vh;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

#main-title {
  text-align: center;
  color: #95E27B;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  padding: 1rem 0;
}

.policy-content {
  background: #1e1e1e; /* Dark content background */
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.3);
  border: 1px solid #333; /* Subtle border */
}

h2 {
  color: #95E27B; /* Using the green accent color */
  font-size: 1.8rem;
  margin: 2.5rem 0 1.5rem;
  border-bottom: 2px solid #333;
  padding-bottom: 0.8rem;
}

h3 {
  color: #b8e6a4; /* Lighter shade of the accent color */
  font-size: 1.4rem;
  margin: 2rem 0 1rem;
}

h4 {
  color: #d4eeca; /* Even lighter shade */
  font-size: 1.2rem;
  margin: 1.5rem 0 1rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.8;
  color: #e1e1e1; /* Light gray for better readability */
}

/* Add spacing between sections */
section + section {
  margin-top: 2rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .privacy-policy {
    padding: 1rem;
  }

  .policy-content {
    padding: 1.5rem;
  }

  #main-title {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem;
  }
}

/* Print styles */
@media print {
  .privacy-policy {
    padding: 0;
    background: white;
    color: black;
  }

  .policy-content {
    box-shadow: none;
    padding: 0;
    background: white;
  }

  h2, h3, h4 {
    color: black;
  }

  p {
    color: black;
  }
}
</style>