<template>
  <Overlays />
  <main>
    <!-- App Download Section -->
    <section class="app-section">
      <div class="app-content">
        <div class="app-text">
          <h2>Download Reward VPN</h2>
          <p>Get secure, fast, and reliable VPN service on your mobile device.</p>
          
          <div class="download-buttons">
            <a href="#" class="store-button">
              <img src="@/assets/google-play-badge.png" alt="Get it on Google Play">
            </a>
            <a href="#" class="store-button">
              <img src="@/assets/app-store-badge.png" alt="Download on App Store">
            </a>
          </div>
        </div>

        <div class="app-mockup">
          <img src="@/assets/mockup.png" alt="Reward VPN App" class="mockup-image">
        </div>
      </div>
    </section>

    <!-- <Section
      format="left"
      heading=""
      action=""
      text=""
    ></Section> -->
  </main>
</template>

<script>
import Overlays from "./components/Overlays";
// import Section from "./components/Section";
import "./css/animations.css";

export default {
  name: "Home",
  components: {
    // Section,
    Overlays,
  },
  created() {
    document.title = "Reward VPN";
  },
};
</script>

<style scoped>
.app-section {
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.3);
 
  margin: 2rem 0;
}

.app-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.app-text {
  flex: 1;
  padding-right: 2rem;
}

.app-text h2 {
  color: #95E27B;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.app-text p {
  color: #e1e1e1;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.download-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.store-button {
  display: inline-block;
  transition: transform 0.3s ease;
}

.store-button:hover {
  transform: translateY(-5px);
}

.store-button img {
  height: 50px;
  width: auto;
}

.app-mockup {
  flex: 1;
  display: flex;
  justify-content: center;
}

.mockup-image {
  max-width: 300px;
  height: auto;
  filter: drop-shadow(0 0 20px rgba(149, 226, 123, 0.3));
}

@media (max-width: 768px) {
  .app-content {
    flex-direction: column;
    text-align: center;
  }

  .app-text {
    padding-right: 0;
  }

  .download-buttons {
    justify-content: center;
  }

  .mockup-image {
    max-width: 250px;
    margin-top: 2rem;
  }
}
</style>