<template lang="en">
    <main>
        Destinations
    </main>
</template>
<script>
export default {
  name: "Destinations",
};
</script>
<style></style>
