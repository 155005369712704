<template>
  <footer>
    <div class="copyright">Reward VPN &copy; 2024. All Rights Reserved.</div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
  color: #adacac;
  background-color: black;
}
</style>
