<template lang="en">
    <main>
        Contact
    </main>
</template>
<script>
export default {
  name: "Contact",
};
</script>
<style></style>
